import React, { FC } from "react";

import cn from "classnames";

import { CommonComponent } from "../../common/Components/common";
import CenterAlertWithText from "../../common/Notifications/CenterAlertWithText";
import Cat from "../../icons/cat.svg";

export interface Error404MainProps extends CommonComponent {}

export const Error404Main: FC<Error404MainProps> = (props) => {
    const { className } = props;

    return (
        <div className={cn(className)}>
            <CenterAlertWithText topNode={<Cat />} headerId="general.404-page.title" />
        </div>
    );
};
