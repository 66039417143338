import React, { FC } from "react";

import Layout from "../components/common/Layout/Layout";
import { Error404Main } from "../components/errors/Error404Main/Error404Main";
import { breadcrumbsProposalsPageIndex } from "../components/pages/breadCrumps.models";
import { useI18nLocaleFormat } from "../hooks/useI18nLocaleFormat";

// https://nextjs.org/docs/advanced-features/custom-error-page

export interface Custom404PageProps {}

const Custom404Page: FC<Custom404PageProps> = (props) => {
    const {} = props;
    const { localeAs } = useI18nLocaleFormat();

    return (
        <Layout
            metaTitle={localeAs("page.home.meta.title")}
            title={localeAs("page.home.content.title")}
            breadcrumbsModel={breadcrumbsProposalsPageIndex(localeAs)}
        >
            <Error404Main />
        </Layout>
    );
};

export default Custom404Page;
